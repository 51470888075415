// SideBarRebateItem.tsx
import React from 'react';
import { formatPrice } from '../../../utils';

interface SidebarRebateItemProps {
    promotion?: {
        rebate?: {
            type: string;
            amount: number;
        };
        leadsource: string;
    };
    prices?: {
        totalPriceAfterRebate: number;
    };
}

const SidebarRebateItem: React.FC<SidebarRebateItemProps> = ({ promotion, prices }) => {
    const reducedPrice = React.useMemo(() => {
        if (prices?.totalPriceAfterRebate !== undefined && prices?.totalPriceAfterRebate !== null) {
            return formatPrice(prices?.totalPriceAfterRebate);
        } else {
            return 'Fehler bei Preisberechnung';
        }
    }, [prices?.totalPriceAfterRebate]);

    const promotionLabel = React.useMemo(() => {
        let label = `Promotion "${promotion?.leadsource}": -${promotion?.rebate?.amount}`;
        if (promotion?.rebate?.type === 'absolute') {
            label = `${label}€`;
        } else {
            label = `${label}%`;
        }
        return label;
    }, [promotion?.leadsource, promotion?.rebate?.amount, promotion?.rebate?.type]);

    if (!promotion?.rebate) {
        return null;
    }

    if (!prices?.totalPriceAfterRebate) {
        return <div style={{ color: 'green' }}>{promotionLabel}</div>;
    }

    return (
        <div>
            <div style={{ color: 'green' }}>{promotionLabel}</div>
            <div style={{ fontWeight: 'bold' }}>Gesamtpreis inkl. Rabatt: {reducedPrice}</div>
        </div>
    );
};

export default SidebarRebateItem;
