import React from 'react';
import MainDisplay from './views/MainDisplay/MainDisplay';
import { Repository } from './services/repository';
import { ContextType, RepositoryData } from './types';
import Loader from './components/Loader/Loader';
import { requestFromBackend } from './utils';

export const RepositoryContext = React.createContext<ContextType>({ repository: {} as Repository });

function App() {
    const [data, setData] = React.useState<RepositoryData | undefined>();
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const fetchData = async () => {
        try {
            const repoData = await requestFromBackend('GET', '/data');
            if (repoData) {
                setData({ ...repoData });
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(true);
        }
    };

    const repository = React.useMemo(() => {
        if (data) return new Repository(data);
    }, [data]);

    React.useEffect(() => {
        fetchData();
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <RepositoryContext.Provider value={{ repository: repository as Repository }}>
            <MainDisplay />
        </RepositoryContext.Provider>
    );
}

export default App;
