import React from 'react';

import ViewDisplay from '../ViewDisplay/ViewDisplay';
import ChangeViewButton from '../../components/ChangeViewButton/ChangeViewButton';
import { PackageItem, PersonalFormInfo, View } from '../../types';
import { ButtonType } from '../../enums';
// import HomeDisplay from '../HomeDisplay/HomeDisplay';
import Stepper from '../../components/Stepper/Stepper';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { RepositoryContext } from '../../App';
import ImprintFooter from '../../components/ImprintFooter/ImprintFooter';

const defaultInfo: PersonalFormInfo = {
    title: 'Herr',
    firstName: '',
    lastName: '',
    phone: '',
    street: '',
    streetNo: undefined,
    postal: undefined,
    city: '',
    country: '',
    email: ''
};

const MainDisplay = () => {
    const { repository } = React.useContext(RepositoryContext);
    const moduleView = repository.views[0];

    const [view, setView] = React.useState<View>(moduleView);
    const [items, setItems] = React.useState<PackageItem[]>([]);
    const [info, setInfo] = React.useState<PersonalFormInfo>(defaultInfo);
    const [showErrorFields, setShowErrorFields] = React.useState<boolean>(false);
    const [tosAccepted, setTOSAccepted] = React.useState<boolean>(false);

    /**
     * Set view to undefined as default value for the HomeDisplay component to be loaded first
     * Otherwise this opens the application straight in the ModuleView
     */
    // if (!view) {
    //     return <HomeDisplay view={view} setView={setView} />;
    // }

    return (
        <div className="configuratorWrapper">
            <div className="configuratorLeftArea">
                <Header />
                <main>
                    <section className="configuratorMainArea">
                        <Stepper view={view} items={items} setView={setView} />
                        <ViewDisplay
                            view={view}
                            items={items}
                            info={info}
                            showErrorFields={showErrorFields}
                            tosAccepted={tosAccepted}
                            setView={setView}
                            setItems={setItems}
                            setInfo={setInfo}
                            setTOSAccepted={setTOSAccepted}
                        />
                        <div className="configuratorPages">
                            <ChangeViewButton
                                view={view}
                                items={items}
                                info={info}
                                type={ButtonType.BACK}
                                tosAccepted={tosAccepted}
                                setView={setView}
                                setItems={setItems}
                                showErrorFields={showErrorFields}
                                setShowErrorFields={setShowErrorFields}
                            />
                            <ChangeViewButton
                                view={view}
                                items={items}
                                info={info}
                                type={ButtonType.NEXT}
                                tosAccepted={tosAccepted}
                                setView={setView}
                                setItems={setItems}
                                showErrorFields={showErrorFields}
                                setShowErrorFields={setShowErrorFields}
                            />
                        </div>
                        <ImprintFooter target="desktop" />
                    </section>
                </main>
            </div>
            <Sidebar items={items} view={view} setView={setView} />
        </div>
    );
};

export default MainDisplay;
