import { PackageItem, PersonalFormInfo } from '../types';
import { requestFromBackend } from '../utils';

class _PDFService {
    async createPdf(info: PersonalFormInfo, items: PackageItem[]) {
        try {
            const data = await requestFromBackend('POST', '/configuration', {
                receiver: info,
                config: items
            });

            return data?.configId;
        } catch (err) {
            console.error(err);
            return;
        }
    }

    async downloadPdf(configId: string) {
        try {
            const data = await requestFromBackend(
                'GET',
                `/configuration/${configId}`,
                undefined,
                undefined,
                'arraybuffer'
            );
            return data;
        } catch (err) {
            console.error(err);
            return;
        }
    }
}

const PDFService = new _PDFService();
export default PDFService;
