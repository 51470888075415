import React from 'react';

import { RepositoryContext } from '../../App';
import { ButtonType, ViewId } from '../../enums';
import { PackageItem, PersonalFormInfo, View } from '../../types';
import FinishButton from '../FinishButton/FinishButton';

type Props = {
    type: ButtonType;
    view: View;
    items: PackageItem[];
    info: PersonalFormInfo;
    showErrorFields: boolean;
    tosAccepted: boolean;
    setView: (view: View) => void;
    setItems: (items: PackageItem[]) => void;
    setShowErrorFields: (showErrorFields: boolean) => void;
};

const ChangeViewButton = (props: Props) => {
    const { repository } = React.useContext(RepositoryContext);

    const [classname, setClassname] = React.useState<string>('button_secondary');

    React.useEffect(() => {
        const isMandatory =
            props.view?.mandatory && !props.items.find((i) => i.viewId === props.view?.id);

        if (props.type === ButtonType.NEXT) {
            const moduleItem = props.items.find((i) => i.viewId === ViewId.MODULE);

            if (isMandatory || !moduleItem?.units) {
                setClassname('button_disabled next');
                return;
            }

            if (!props.view?.nextId) {
                setClassname('button_hidden');
                return;
            }

            setClassname('button_primary next');
            return;
        }

        if (props.type === ButtonType.BACK) {
            if (!props.view?.previousId) {
                setClassname('button_hidden');
                return;
            }

            setClassname('button_secondary prev');
            return;
        }
    }, [
        props.items,
        props.type,
        props.view?.id,
        props.view?.mandatory,
        props.view?.nextId,
        props.view?.previousId
    ]);

    const handleOnClick = () => {
        if (classname.includes('button_disabled')) return;

        const newViewId =
            props.type === ButtonType.NEXT ? props.view?.nextId : props.view?.previousId;
        const newView = repository.getViewById(newViewId as ViewId);
        if (!newView) return;

        props.setView(newView);
    };

    if (props.view.id === ViewId.PERSONAL && props.type === ButtonType.NEXT) {
        return (
            <FinishButton
                items={props.items}
                info={props.info}
                tosAccepted={props.tosAccepted}
                setItems={props.setItems}
                setView={props.setView}
                setShowErrorFields={props.setShowErrorFields}
            />
        );
    }

    return (
        <button data-testid="change-view-button" className={classname} onClick={handleOnClick}>
            {props.type === ButtonType.NEXT ? 'weiter' : 'zurück'}
        </button>
    );
};

export default ChangeViewButton;
