export enum ButtonType {
    NEXT = 'next',
    BACK = 'back'
}

export enum ViewId {
    MODULE = 'module',
    INVERTER = 'inverter',
    STORAGE = 'storage',
    WALLBOX = 'wallbox',
    OPTIONS = 'options',
    PERSONAL = 'personal'
}
