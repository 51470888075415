import React from 'react';

import { RepositoryContext } from '../../App';
import { PackageItem, View, Promotion } from '../../types';
import { ViewId } from '../../enums';
import { formatPrice } from '../../utils';
import SidebarItem from './SidebarItem/SidebarItem';
import SidebarOptionItem from './SidebarOptionItem/SidebarOptionItem';
import ImprintFooter from '../ImprintFooter/ImprintFooter';
import { requestFromBackend } from '../../utils';
import SidebarRebateItem from './SidebarRebateItem/SidebarRebateItem';

type Props = {
    items: PackageItem[];
    view: View;
    setView: (view: View) => void;
};

const Sidebar = (props: Props) => {
    const { repository } = React.useContext(RepositoryContext);
    const [prices, setPrices] = React.useState<
        { totalPrice: number; totalPriceAfterRebate: number } | undefined
    >();
    const [promotion, setPromotion] = React.useState<Promotion | undefined>();

    const checkForRefCode = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const refCode = urlParams.get('refCode');
        if (refCode) {
            try {
                const promo = await requestFromBackend('POST', '/data/referral', { refCode });
                setPromotion(promo); // Set the promotion data
            } catch (err) {
                console.log(err);
            }
        }
    };

    React.useEffect(() => {
        checkForRefCode();
    }, []);

    React.useEffect(() => {
        if (props.items.length === 0 && props.view.id === ViewId.MODULE) {
            setPrices(undefined);
        }
    }, [props.items.length, props.view.id]);

    const defaultSummary = React.useMemo(() => {
        if (props.items.length === 0) {
            return <div>Sie haben aktuell keine Module ausgewählt</div>;
        }
        return false;
    }, [props.items.length]);

    const itemsContent = React.useMemo(() => {
        return props.items.map((item, index) => {
            if (item.viewId == ViewId.OPTIONS) {
                if (item.options?.length === 0) return false;

                return (
                    <SidebarOptionItem
                        key={index}
                        index={index}
                        item={item}
                        view={props.view}
                        setView={props.setView}
                    />
                );
            }

            const card = repository.getCardById(item.cardId);
            if (!card || card.id.startsWith('no-')) return false;

            return (
                <SidebarItem
                    key={index}
                    index={index}
                    item={item}
                    card={card}
                    view={props.view}
                    setView={props.setView}
                />
            );
        });
    }, [props, repository]);

    const getPackagePrice = async () => {
        const prices = await repository.getPackagePrice(props.items);
        if (prices) {
            setPrices(prices);
            return;
        }
    };

    React.useEffect(() => {
        if (props.items && props.items.length !== 0) {
            getPackagePrice();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.items]);

    return (
        <aside className="configuratorRightArea">
            <div className="text-center">
                <h3 className="h2">Ihre Auswahl</h3>
                <h4>
                    Schlüsselfertige Konfiguration inkl. Anmeldungen, Projektierung, Lieferung und
                    Montage.
                </h4>
                {defaultSummary}
            </div>
            <div className="buyBox__overview">
                {itemsContent}
                <div>
                    {window.location.href.includes('angebot') && (
                        <div className="overviewItemPrices">
                            <b>Gesamtpreis: {formatPrice(prices?.totalPrice ?? 0)}</b>
                        </div>
                    )}
                    <SidebarRebateItem promotion={promotion} prices={prices} />
                </div>
                {window.location.href.includes('angebot') && (
                    <div className="overviewItem__description">
                        (inkl. Mehrwertsteuer in der jeweils geltenden Höhe)
                    </div>
                )}
            </div>

            <ImprintFooter target="mobile" />
        </aside>
    );
};

export default Sidebar;
