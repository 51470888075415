import React from 'react';
// import { formatPrice } from '../../../utils';
import { PackageItem, View, Option } from '../../../types';

type Props = {
    index: number;
    item: PackageItem;
    view: View;
    setView: (view: View) => void;
};

const SidebarOptionItem = (props: Props) => {
    return (
        <div key={props.index}>
            <div className="overviewItem overviewItem__options--noBorder">
                <div className="overviewItem__headline">Zusatzleistungen</div>
            </div>
            {props.item.options?.map((opt, optIndex) => {
                const option = opt as Option;
                return (
                    <div key={optIndex} className="overviewItem overviewItem__options">
                        <div className="overviewItem__description">
                            <span className="overviewItem__quantity">{option.amount ?? '1'}x </span>
                            {option.title}
                        </div>
                        {/* <div className="overviewItem__price">{formatPrice(option.totalPrice ?? 0)}</div> */}
                    </div>
                );
            })}
        </div>
    );
};

export default SidebarOptionItem;
