import React from 'react';

const DisabledOverlay = () => {
    return (
        <div className="disabled-overlay" style={styles.container}>
            <p style={styles.text}>
                Diese Komponente ist bei Ihrer
                <br />
                aktuellen Konfiguration nicht möglich.
            </p>
        </div>
    );
};

const styles: { [component: string]: React.CSSProperties } = {
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        paddingTop: '50%'
    },
    text: {
        textAlign: 'center',
        transform: 'rotate(-45deg)',
        color: '#ff9027',
        fontWeight: 'bolder',
        fontSize: '18px'
    }
};

export default DisabledOverlay;
