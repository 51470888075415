import React from 'react';

import ModuleView from '../ModuleView/ModuleView';
import { PackageItem, PersonalFormInfo, View } from '../../types';
import { ViewId } from '../../enums';
import InverterView from '../InverterView/InverterView';
import StorageView from '../StorageView/StorageView';
import WallboxView from '../WallboxView/WallboxView';
import OptionsView from '../OptionsView/OptionsView';
import PersonalView from '../PersonalView/PersonalView';
import { isMobile } from '../../utils';

type Props = {
    view: View;
    items: PackageItem[];
    info: PersonalFormInfo;
    showErrorFields: boolean;
    tosAccepted: boolean;
    setItems: (items: PackageItem[]) => void;
    setInfo: (info: PersonalFormInfo) => void;
    setView: (view: View) => void;
    setTOSAccepted: (tosAccepted: boolean) => void;
};

const ViewDisplay = (props: Props) => {
    React.useEffect(() => {
        if (isMobile()) {
            window.scrollTo(0, 0);
        }
    }, [props.view]);

    const ViewProps: Omit<
        Props,
        'info' | 'setInfo' | 'showErrorFields' | 'tosAccepted' | 'setTOSAccepted'
    > = React.useMemo(
        () => ({
            view: props.view,
            items: props.items,
            setView: props.setView,
            setItems: props.setItems
        }),
        [props.items, props.setItems, props.setView, props.view]
    );

    const viewContent = React.useMemo(() => {
        switch (props.view.id) {
            case ViewId.INVERTER:
                return <InverterView {...ViewProps} />;
            case ViewId.STORAGE:
                return <StorageView {...ViewProps} />;
            case ViewId.WALLBOX:
                return <WallboxView {...ViewProps} />;
            case ViewId.OPTIONS:
                return <OptionsView {...ViewProps} />;
            case ViewId.PERSONAL:
                return (
                    <PersonalView
                        info={props.info}
                        setInfo={props.setInfo}
                        showErrorFields={props.showErrorFields}
                        tosAccepted={props.tosAccepted}
                        setTOSAccepted={props.setTOSAccepted}
                    />
                );
            case ViewId.MODULE:
            default:
                return <ModuleView {...ViewProps} />;
        }
    }, [
        ViewProps,
        props.info,
        props.setInfo,
        props.setTOSAccepted,
        props.showErrorFields,
        props.tosAccepted,
        props.view.id
    ]);

    return (
        <div data-testid="view-display" className="configuratorMainArea__bottom">
            {viewContent}
        </div>
    );
};

export default ViewDisplay;
