import React from 'react';

import { PackageItem, PersonalFormInfo, View } from '../../types';
import { Buffer } from 'buffer';
import { isMobile, submitPackage } from '../../utils';
import Modal from '../Modal/Modal';
window.Buffer = Buffer;

type Props = {
    items: PackageItem[];
    info: PersonalFormInfo;
    tosAccepted: boolean;
    setItems: (items: PackageItem[]) => void;
    setView: (view: View) => void;
    setShowErrorFields: (showErrorFields: boolean) => void;
};

const FinishButton = (props: Props) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [configurationId, setConfigurationId] = React.useState<string>();

    const validateFields = (info: PersonalFormInfo) => {
        const noContact = !info.firstName || !info.lastName || !info.email || !info.phone;
        const noAddress = !info.street || !info.streetNo || !info.city || !info.postal;
        const noTitle = !info.title;

        if (noContact || noAddress || noTitle) {
            props.setShowErrorFields(true);
            return false;
        }
        props.setShowErrorFields(false);
        return true;
    };

    const handleOnClick = async () => {
        if (!props.tosAccepted) return;

        const validated = validateFields(props.info);
        if (!validated) return;

        submitPackage(props.info, props.items).then((response) => {
            setConfigurationId(response.id);
        });

        if (isMobile()) {
            window.scroll(0, 0);
        }
        setShowModal(true);
    };

    return (
        <div>
            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                setView={props.setView}
                setItems={props.setItems}
                configurationId={configurationId}
            />
            <button
                className={props.tosAccepted ? 'button_action finish' : 'button_disabled'}
                onClick={handleOnClick}>
                Angebot anfordern
            </button>
        </div>
    );
};

export default FinishButton;
