import { ViewId } from '../enums';
import {
    Card,
    Inverter,
    Module,
    View,
    Storage,
    OptionCategory,
    RepositoryData,
    PackageItem,
    Promotion
} from '../types';
import { requestFromBackend } from '../utils';

export class Repository {
    cards: Card[];
    views: View[];
    modules: Module[];
    inverters: Inverter[];
    storages: Storage[];
    optionCategories: OptionCategory[];
    promotions: Promotion[];

    constructor(data: RepositoryData) {
        this.cards = data.cards;
        this.views = data.views;
        this.modules = data.modules;
        this.inverters = data.inverters;
        this.storages = data.storages;
        this.optionCategories = data.options;
        this.promotions = data.promotions;
    }

    getViewById(viewId: ViewId): View | undefined {
        return this.views.find((view: View) => view.id === viewId);
    }

    getNextViewIds(viewId: ViewId | undefined): ViewId[] {
        if (!viewId) return this.views.map((view) => view.id);

        const nextIds: ViewId[] = [];
        let currentView: View | undefined = this.getViewById(viewId);
        while (currentView?.nextId) {
            nextIds.push(currentView.nextId);
            currentView = this.getViewById(currentView.nextId);
        }

        return nextIds;
    }

    getPreviousViewIds(viewId: ViewId): ViewId[] {
        const previousIds: ViewId[] = [];
        let currentView: View | undefined = this.getViewById(viewId);
        while (currentView?.previousId) {
            previousIds.push(currentView.previousId);
            currentView = this.getViewById(currentView.previousId);
        }

        return previousIds;
    }

    getCardById(cardId: string): Card | undefined {
        return this.cards.find((card: Card) => card.id === cardId);
    }

    getCardsFromView(viewId: ViewId): Card[] {
        return this.cards.filter((card: Card) => card.viewId === viewId);
    }

    getCardsDisabledIds(cards: Card[]): string[] {
        return cards.map((card) => card?.disableIds).flat();
    }

    getStorageRecommendedOptionId(id: string, units: number): string | undefined {
        const storage = this.storages.find((st) => st.id === id);
        if (!storage) return;

        // repository is sorted - change this if repository changes
        const recommendedOpt = storage.options.find((opt) => opt.unitsLimit >= units);
        return recommendedOpt ? recommendedOpt.id : undefined;
    }

    getStorageOptions(id: string): { id: string; label: string; price: number }[] | undefined {
        const storage = this.storages.find((st) => st.id === id);
        if (!storage) return;

        return storage.options;
    }

    getOptionCategories() {
        return this.optionCategories;
    }

    getOptions() {
        return this.optionCategories.map((category) => category.items).flat();
    }

    async getModulePrice(id: string, units: number | undefined): Promise<number | undefined> {
        const data = await requestFromBackend('GET', `/data/price/module?id=${id}&units=${units}`);
        return data.price;
    }

    async getInverterPrice(id: string, units: number | undefined): Promise<number | undefined> {
        const data = await requestFromBackend(
            'GET',
            `/data/price/inverter?id=${id}&units=${units}`
        );
        return data.price;
    }

    async getOptionPrice(
        optionItemId: string,
        amount?: number,
        units?: number
    ): Promise<number | undefined> {
        const queryUnits = units ? `&units=${units}` : '';
        const queryAmount = amount ? `&amount=${amount}` : '';

        const data = await requestFromBackend(
            'GET',
            `/data/price/options?optionItemId=${optionItemId}${queryUnits}${queryAmount}`
        );
        return data.price;
    }

    async getPackagePrice(
        packageItems: PackageItem[]
    ): Promise<{ totalPrice: number; totalPriceAfterRebate: number } | undefined> {
        const urlParams = new URLSearchParams(window.location.search);
        const refCode = urlParams.get('refCode');
        const data = await requestFromBackend('POST', '/data/price', {
            items: packageItems,
            refCode: refCode
        });
        if (data) {
            return {
                totalPrice: data.totalPrice,
                totalPriceAfterRebate: data.totalPriceAfterRebate
            };
        }
        return undefined;
    }

    async getPromotion(refCode: string): Promise<Promotion> {
        const data = await requestFromBackend(
            'GET',
            `/price/refcode?refCode=${encodeURIComponent(refCode)}`
        );
        return data;
    }
}
