import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import PDFService from '../../services/pdf';

const OfferPDFView = () => {
    const { id } = useParams();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [PDFContent, setPDFContent] = React.useState<string>();
    const [isMobile, setIsMobile] = React.useState<boolean>(false);

    const loadPDF = React.useCallback(async () => {
        setIsLoading(true);
        if (id) {
            const fileContent = await PDFService.downloadPdf(id);
            if (fileContent) {
                const file = new Blob([fileContent], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);

                const isIpad =
                    /Macintosh/i.test(navigator.userAgent) &&
                    navigator.maxTouchPoints &&
                    navigator.maxTouchPoints > 1;
                if (
                    /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent) ||
                    isIpad
                ) {
                    setIsMobile(true);
                    // window.location.replace(fileURL);
                    window.open(fileURL, '_self');
                } else {
                    setPDFContent(fileURL);
                }

                // window.location.href = fileURL;
            }
        } else {
            console.error('No id found in URL. Format: /angebot/:id');
        }
        setIsLoading(false);
    }, [id]);

    React.useEffect(() => {
        loadPDF();
    }, [loadPDF]);

    if (!PDFContent || isLoading) {
        return (
            <div>
                <Header />
                <p>loading</p>
            </div>
        );
    } else if (isMobile) {
        return (
            <div>
                <Header />
                <p>Ihr Angebot wurde in einem neuen Tab geöffnet.</p>
            </div>
        );
    } else {
        return (
            <div style={{ height: '100vh' }}>
                <Header />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '50px',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        height: '100%'
                    }}>
                    <object
                        style={{ width: '100vw', height: '100vw' }}
                        data={PDFContent}
                        // type="application/pdf"
                    />
                </div>
            </div>
        );
    }
};

export default OfferPDFView;
