import React from 'react';

import { Option } from '../../../types';
import { RepositoryContext } from '../../../App';
import { formatPrice } from '../../../utils';

type Props = {
    units: number | undefined;
    option: Option;
    selectedOptions: Option[];
    isDisabled: boolean;
    setSelectedOptions: (selectedOptions: Option[]) => void;
};

const translateUnit = (unit: 'perpiece' | 'flat' | 'permodule'): string => {
    switch (unit) {
        case 'flat':
            return 'pauschal';
        case 'perpiece':
            return 'pro Stück';
        case 'permodule':
            return 'pro Modul';
        default:
            return 'UNBEKANNTE EINHEIT';
    }
};

const OptionItem = (props: Props) => {
    const { repository } = React.useContext(RepositoryContext);
    const repositoryOptions = React.useMemo(() => repository.getOptions(), [repository]);

    const getDefaultAmount = () => {
        const foundOpt = props.selectedOptions.find((opt) => opt.id === props.option.id);
        if (!foundOpt) return;

        return foundOpt.amount;
    };
    const [amount, setAmount] = React.useState<number | undefined>(getDefaultAmount());

    const getDefaultIsSelected = () =>
        !!props.selectedOptions.find((opt) => opt.id === props.option.id);
    const [isSelected, setIsSelected] = React.useState<boolean>(getDefaultIsSelected());

    const removeOption = React.useCallback(() => {
        const removedOptions = props.selectedOptions.filter((opt) => opt.id !== props.option.id);
        props.setSelectedOptions([...removedOptions]);
    }, [props]);

    const addOption = React.useCallback(
        async (numericalAmount?: number) => {
            const filterOptions = props.selectedOptions.filter((opt) => opt.id !== props.option.id);

            const foundOption = repositoryOptions.find((opt) => opt.id === props.option.id);
            const totalPrice = await repository.getOptionPrice(
                props.option.id,
                numericalAmount,
                props.units
            );

            if (!foundOption) return;
            props.setSelectedOptions([
                ...filterOptions,
                { ...foundOption, totalPrice, amount: numericalAmount }
            ]);
        },
        [props, repository, repositoryOptions]
    );

    const onCheckboxChange = async () => {
        setIsSelected(!isSelected);

        if (!isSelected && hasQuantityInput) {
            setAmount(1);
        }

        const selectedOption = props.selectedOptions.find((opt) => opt.id === props.option.id);
        if (!selectedOption) {
            await addOption(amount ?? 1);
        } else {
            removeOption();
        }
    };

    const onInputChange = React.useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.target.value) {
                setIsSelected(false);
                setAmount(undefined);
                removeOption();
            } else {
                const numericalAmount = parseInt(e.target.value ?? '');
                setIsSelected(true);
                setAmount(numericalAmount);
                await addOption(numericalAmount);
            }
        },
        [addOption, removeOption]
    );

    const hasQuantityInput = props.option.type === 'perpiece';
    const priceElement = React.useMemo(() => {
        if (hasQuantityInput) {
            return (
                <span className="priceQuantityWrapper">
                    <span className="quantity">
                        <input
                            type="number"
                            className="text noCheckbox"
                            name="input_quantity_construction"
                            id="input_quantity_construction"
                            placeholder="Menge"
                            value={amount}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                        />
                    </span>
                    <span className="priceWrapper">
                        {window.location.href.includes('angebot') && (
                            <span className="basePrice">{formatPrice(props.option.price)}</span>
                        )}
                        <span className="priceSubline">
                            {`${translateUnit(props.option.type)} ${
                                props.option.taxPercent
                                    ? `zzgl. ${props.option.taxPercent}% MwSt.`
                                    : ''
                            }`}
                        </span>
                    </span>
                </span>
            );
        } else {
            return (
                <span className="priceWrapper">
                    {window.location.href.includes('angebot') && (
                        <span className="basePrice">{formatPrice(props.option.price)}</span>
                    )}
                    <span className="priceSubline">
                        {`${translateUnit(props.option.type)} ${
                            props.option.taxPercent ? `zzgl. ${props.option.taxPercent}% MwSt.` : ''
                        }`}
                    </span>
                </span>
            );
        }
    }, [
        amount,
        hasQuantityInput,
        onInputChange,
        props.option.price,
        props.option.taxPercent,
        props.option.type
    ]);

    return (
        <div className={`form-group checkbox-group ${hasQuantityInput ? 'withQuantity' : ''}`}>
            <label className={`specialcheckbox ${props.isDisabled ? 'disabled' : ''}`}>
                <input
                    className="form-check-input"
                    id={props.option.id}
                    type="checkbox"
                    value="privacy"
                    checked={isSelected}
                    disabled={props.isDisabled}
                    onChange={onCheckboxChange}
                />
                <label htmlFor={props.option.id} />
                <p>
                    <span>
                        <strong>{props.option.title}</strong>
                        {props.option.description}
                    </span>

                    {priceElement}
                </p>
            </label>
        </div>
    );
};

export default OptionItem;
