import axios, { AxiosHeaders, AxiosRequestConfig, Method, ResponseType } from 'axios';
import { PackageItem, PersonalFormInfo } from './types';

export const formatPrice = (price: number): string => {
    return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
    }).format(price);
};

export const requestFromBackend = async (
    method: Method,
    endpoint: string,
    body?: { [key: string]: unknown },
    headers?: AxiosHeaders,
    responseType?: ResponseType
) => {
    const backendUrl = 'https://konfigurator.calosol.de/api';
    // const backendUrl = 'http://localhost:5001';
    const url = `${backendUrl}${endpoint}`;

    const config: AxiosRequestConfig = {
        method,
        url,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (headers) config.headers = headers;
    if (body) config.data = body;
    if (responseType) config.responseType = responseType;

    try {
        const response = await axios.request(config);
        if (response.data) {
            return response.data;
        } else {
            return {};
        }
    } catch (err) {
        console.error(`Request failed: ${err}`);
        return {};
    }
};

export const submitPackage = async (
    info: PersonalFormInfo,
    items: PackageItem[]
): Promise<{ id?: string }> => {
    const responseData = await requestFromBackend('POST', '/configuration', { info, items });
    return responseData;
};

export const isMobile = () => {
    const minWidth = 768;
    return window.innerWidth < minWidth || screen.width < minWidth;
};
