import React from 'react';

type Properties = {
    target: 'mobile' | 'desktop';
};

const ImprintFooter = (props: Properties) => {
    if (props.target === 'desktop') {
        return (
            <div className="hidden-xs hidden-sm">
                <ul className="configuratorNavigation">
                    <li>
                        <a href="https://www.calosol.de/de/info/datenschutz/">Datenschutz</a>
                    </li>
                    <li>
                        <a href="https://www.calosol.de/de/info/impressum/">Impressum</a>
                    </li>
                </ul>
            </div>
        );
    } else {
        return (
            <div className="visible-xs visible-sm">
                <ul className="configuratorNavigation">
                    <li>
                        <a href="https://www.calosol.de/de/info/datenschutz/">Datenschutz</a>
                    </li>
                    <li>
                        <a href="https://www.calosol.de/de/info/impressum/">Impressum</a>
                    </li>
                </ul>
            </div>
        );
    }
};

export default ImprintFooter;
