import React from 'react';

import { Card, CardOption, PackageItem } from '../../types';
import { RepositoryContext } from '../../App';
import { ViewId } from '../../enums';
import { formatPrice } from '../../utils';

type Props = {
    card: Card;
    cardDisabled: boolean;
    items: PackageItem[];
    defaultOptionId: string | undefined;
    setItems: (items: PackageItem[]) => void;
    onSelectOption: (opt: CardOption) => void;
    onDisableCard: (isDisabled: boolean) => void;
    setDefaultOptionId: (optionId: string | undefined) => void;
};

const CardDropdown = (props: Props) => {
    const { repository } = React.useContext(RepositoryContext);

    const disabledIds = React.useMemo(() => {
        const cards = props.items.map((item) => repository.getCardById(item.cardId));
        return repository.getCardsDisabledIds(cards as Card[]);
    }, [props.items, repository]);

    React.useEffect(() => {
        const cardOptionIds = props.card?.options?.map((option) => option.id);
        const allOptionsDisabled = cardOptionIds?.every((id) => disabledIds.includes(id));
        if (allOptionsDisabled) {
            props.onDisableCard(true);
            return;
        }

        const availableOptionIds = cardOptionIds?.filter((id) => !disabledIds.includes(id));
        if (availableOptionIds?.length === 1) {
            props.setDefaultOptionId(availableOptionIds[0]);
            return;
        }

        const selectedViewItem = props.items.find(
            (item) => item.viewId === props.card.viewId && item.cardId === props.card.id
        );
        if (selectedViewItem?.options?.length === 1) {
            const selectedOptionId = selectedViewItem.options[0].id;
            props.setDefaultOptionId(selectedOptionId);
            return;
        }

        const moduleItem = props.items.find((item) => item.viewId === ViewId.MODULE);
        if (!moduleItem) return;

        const units = moduleItem.units;
        const recommendedOptId = repository.getStorageRecommendedOptionId(
            props.card.id,
            units ?? 0
        );

        if (recommendedOptId) {
            props.setDefaultOptionId(recommendedOptId);
        }
    }, [disabledIds, props, repository]);

    const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        if (!value) return;

        const opt = props.card?.options?.find((opt) => opt.id === value) as CardOption;
        props.onSelectOption(opt);

        const { id: cardId, viewId } = props.card;
        const view = repository.getViewById(viewId);
        const nextViewIds = repository.getNextViewIds(view?.previousId);

        const filteredItems = props.items.filter((item) => !nextViewIds.includes(item.viewId));
        props.setItems([...filteredItems, { cardId, viewId, price: opt.price, options: [opt] }]);
    };

    const getOptionLabel = React.useCallback((opt: CardOption) => {
        if (window.location.href.includes('angebot')) {
            return `${opt.label} - ${formatPrice(opt.price)}`;
        } else {
            return opt.label;
        }
    }, []);

    if (!props.card.options) {
        return <div></div>;
    }

    return (
        <div className="form-group select">
            <div className="input select_body ">
                <select
                    className="select"
                    name="input_select_wallbox"
                    id="input_select_wallbox"
                    disabled={props.cardDisabled}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSelectChange(e)}>
                    {!props.defaultOptionId && (
                        <option key={'opt-placeholder'} hidden>
                            Optionen auswählen
                        </option>
                    )}
                    {props.card.options.map((opt) => {
                        return (
                            <option
                                key={opt.id}
                                value={opt.id}
                                disabled={disabledIds.includes(opt.id)}
                                selected={props.defaultOptionId == opt.id}>
                                {getOptionLabel(opt)}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
};

export default CardDropdown;
