import React from 'react';

import { Card, PackageItem, View } from '../../types';
import CardsSelect from '../../components/CardsSelect/CardsSelect';
import { RepositoryContext } from '../../App';
import { ViewId } from '../../enums';

type Props = {
    view: View;
    items: PackageItem[];
    setView: (view: View) => void;
    setItems: (items: PackageItem[]) => void;
};

const sqrmetersPerModule = 2.5;
const defaultUnits = 20;
const defaultArea = defaultUnits * sqrmetersPerModule;

const ModuleView = (props: Props) => {
    const { repository } = React.useContext(RepositoryContext);

    const [area, setArea] = React.useState<number | undefined>(defaultArea);
    const [units, setUnits] = React.useState<number | undefined>(defaultUnits);
    const [cards, setCards] = React.useState<Card[]>([]);

    React.useEffect(() => {
        if (!units) {
            setArea(undefined);
            return;
        }

        setArea(sqrmetersPerModule * units);
    }, [units]);

    React.useEffect(() => {
        if (!area) {
            setUnits(undefined);
            return;
        }

        setUnits(area / sqrmetersPerModule);
    }, [area]);

    React.useEffect(() => {
        const moduleCard = props.items.find((item) => item.viewId === ViewId.MODULE);
        if (!moduleCard) return;

        const prevUnits = moduleCard.units;
        setUnits(prevUnits);
    }, [props.items]);

    const onAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const newInput = value ? parseFloat(value) : undefined;
        setArea(newInput);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onAreaBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newInput = area;

        if (area && area > 33 * sqrmetersPerModule) {
            newInput = 33 * sqrmetersPerModule;
        }

        if (area && area < 9 * sqrmetersPerModule) {
            newInput = 9 * sqrmetersPerModule;
        }

        if (newInput != area) {
            setArea(newInput);
        }
    };

    const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const newInput = value ? parseFloat(value) : undefined;
        setUnits(newInput);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onQuantityBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newInput = units;

        if (units && units > 33) {
            newInput = 33;
        }

        if (units && units < 9) {
            newInput = 9;
        }

        if (newInput != units) {
            setUnits(newInput);
        }
    };

    const getModuleCards = React.useCallback(async () => {
        const cards = repository.getCardsFromView(ViewId.MODULE);
        const mapped = await Promise.all(
            cards.map(async (c) => {
                const price = await repository.getModulePrice(c.id, units);
                return {
                    ...c,
                    price,
                    units: units ? Math.floor(units) : undefined
                };
            })
        );
        setCards([...mapped]);
    }, [repository, units]);

    React.useEffect(() => {
        getModuleCards();
    }, [getModuleCards]);

    return (
        <div>
            <div className="configuratorInputs">
                <div className="configuratorInputs__area">
                    <div>Verfügbare Fläche</div>
                    <div className="">
                        <input
                            type="number"
                            className="text"
                            name="input_area"
                            id="input_area"
                            placeholder="Fläche"
                            onChange={onAreaChange}
                            onBlur={onAreaBlur}
                            onFocus={() => props.setItems([])}
                            value={area}
                        />
                    </div>
                    <div>m²</div>
                </div>
                <div className="configuratorInputs__quantity">
                    <div>Anzahl Module</div>
                    <div>
                        <input
                            type="number"
                            max={33}
                            min={10}
                            className="text"
                            name="input_quantity"
                            id="input_quantity"
                            placeholder=""
                            onChange={onQuantityChange}
                            onBlur={onQuantityBlur}
                            onFocus={() => props.setItems([])}
                            value={units ? Math.floor(units) : undefined}
                        />
                    </div>
                    <div>Stück</div>
                </div>
                <div className="configuratorInputs__hint">
                    <div>
                        Achtung! Sie wünschen mehr als 33 Module?
                        <br />
                        Bitte schreiben Sie uns für eine individuelle Kalkulation.
                        <a href="https://www.calosol.de/de/info/kontakt/"> Kontakt aufnehmen</a>
                    </div>
                </div>
            </div>
            <CardsSelect
                items={props.items}
                setItems={props.setItems}
                cards={cards}
                view={props.view}
                setView={props.setView}
            />
        </div>
    );
};

export default ModuleView;
