import React from 'react';
import './Modal.css';
import '../../styles.css';
import { PackageItem, View } from '../../types';

type Props = {
    showModal: boolean;
    configurationId?: string;
    setShowModal: (showModal: boolean) => void;
    setView: (view: View) => void;
    setItems: (items: PackageItem[]) => void;
};

const Modal = (props: Props) => {
    const onClose = () => {
        window.location.href = 'https://www.calosol.de/';
    };

    const onOpenConfiguration = () => {
        window.open(`https://konfigurator.calosol.de/angebot/${props.configurationId}`, '_blank');
    };

    return (
        <div>
            <section className={props.showModal ? 'modal' : 'modal hidden'}>
                <h3>
                    Geschafft!
                    <br />
                    In wenigen Sekunden können Sie ihr Angebot abrufen. Sie erhalten außerdem noch
                    eine E-Mail mit einem Link zu Ihrem Angebot und weiteren Informationen.
                </h3>

                <button
                    className={props.configurationId ? 'btn-secondary' : 'button_hidden'}
                    onClick={onOpenConfiguration}>
                    Angebot Anzeigen
                </button>
                <button className="btn-secondary" onClick={onClose}>
                    Schließen
                </button>
            </section>
            <div className={props.showModal ? 'overlay' : 'overlay hidden'}></div>
        </div>
    );
};

export default Modal;
