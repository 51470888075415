import React from 'react';

import { RepositoryContext } from '../../App';
import { PackageItem, View } from '../../types';

type Props = {
    view: View;
    items: PackageItem[];
    setView: (view: View) => void;
};

const Stepper = (props: Props) => {
    const { repository } = React.useContext(RepositoryContext);

    const viewIndex = repository.views.indexOf(props.view) + 1;
    const angle = 360 / repository.views.length;
    const fullRotation = viewIndex <= 3 ? 0 : (viewIndex - 3) * angle;
    const rotation = viewIndex <= 3 ? viewIndex * angle : 180;

    return (
        <div className="configuratorMainArea__top">
            <div className="configuratorIntro">
                <h1>{props.view.title}</h1>
                {props.view.subtitle && <h4>{props.view.subtitle}</h4>}
                {/* {props.view.description} */}
            </div>
            <div className="progressCircle" data-progress="16">
                <div className="circle">
                    <div
                        className="full progressCircle__slice"
                        style={{ transform: `rotate(${viewIndex >= 3 ? '180' : '0'}deg)` }}>
                        <div
                            className="progressCircle__fill"
                            style={{ transform: `rotate(${fullRotation}deg)` }}
                        />
                    </div>
                    <div className="progressCircle__slice">
                        <div
                            className="progressCircle__fill"
                            style={{ transform: `rotate(${rotation}deg)` }}
                        />
                        <div
                            className="progressCircle__fill progressCircle__bar"
                            style={{ transform: `rotate(${rotation}deg)` }}
                        />
                    </div>
                </div>
                <div className="progressCircle__overlay">{`${
                    repository.views.indexOf(props.view) + 1
                }/${repository.views.length}`}</div>
            </div>
        </div>
    );
};

export default Stepper;
