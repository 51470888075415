import React from 'react';

import { PackageItem, View } from '../../types';
import CardsSelect from '../../components/CardsSelect/CardsSelect';
import { RepositoryContext } from '../../App';
import { ViewId } from '../../enums';

type Props = {
    view: View;
    items: PackageItem[];
    setView: (view: View) => void;
    setItems: (items: PackageItem[]) => void;
};

const WallboxView = (props: Props) => {
    const { repository } = React.useContext(RepositoryContext);

    const wallboxCards = React.useMemo(() => {
        return repository.getCardsFromView(ViewId.WALLBOX);
    }, [repository]);

    return (
        <div data-testid="inverter-view" className="inverter-view-container">
            <CardsSelect
                items={props.items}
                setItems={props.setItems}
                cards={wallboxCards}
                view={props.view}
                setView={props.setView}
            />
        </div>
    );
};

export default WallboxView;
