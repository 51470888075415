import React from 'react';
import { PersonalFormInfo } from '../../types';

type Props = {
    info: PersonalFormInfo;
    showErrorFields: boolean;
    tosAccepted: boolean;
    setInfo: (info: PersonalFormInfo) => void;
    setTOSAccepted: (tosAccepted: boolean) => void;
};

const PersonalView = (props: Props) => {
    // Get the refCode from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const refCode = urlParams.get('refCode');

    // If refCode is present, set it as the lead source
    if (refCode && !props.info.questionnaire?.source) {
        props.setInfo({
            ...props.info,
            questionnaire: {
                ...props.info.questionnaire,
                source: 'Promotion',
                referrerName: refCode
            }
        });
    }

    const onFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        const questionnaireFields = ['source', 'referrerName', 'company'];
        if (questionnaireFields.includes(name)) {
            props.setInfo({
                ...props.info,
                questionnaire: { ...props.info.questionnaire, [name]: value }
            });
            return;
        }

        props.setInfo({ ...props.info, [name]: value });
    };

    return (
        <div className="formOffer">
            <div className="row gridGap--small">
                <div className="col-xs-12 col-sm-10 col-lg-7">
                    <form name="" id="" method="" action="" encType="multipart/form-data">
                        <div className="row gridGap--small">
                            <div className="col-xs-6 col-sm-3">
                                <div
                                    className={
                                        props.showErrorFields
                                            ? 'form-group select error'
                                            : 'form-group select'
                                    }>
                                    <label htmlFor="input_select_salutation">Anrede</label>
                                    <div className="input select_body ">
                                        <select
                                            className="select"
                                            name="title"
                                            id="input_select_salutation"
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                                onFieldChange(e)
                                            }>
                                            <option selected={true} value="Herr">
                                                Herr
                                            </option>
                                            <option value="Frau">Frau</option>
                                            <option value="Divers">Divers</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-9"></div>
                        </div>

                        <div className="row gridGap--small">
                            <div className="col-xs-12 col-sm-6">
                                <div
                                    className={
                                        props.showErrorFields ? 'form-group error' : 'form-group'
                                    }>
                                    <label htmlFor="input_first_name">Vorname *</label>
                                    <input
                                        type="text"
                                        className="text"
                                        required={true}
                                        name="firstName"
                                        id="input_first_name"
                                        value={props.info.firstName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onFieldChange(e)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div
                                    className={
                                        props.showErrorFields ? 'form-group error' : 'form-group'
                                    }>
                                    <label htmlFor="input_last_name">Nachname *</label>
                                    <input
                                        type="text"
                                        className="text"
                                        required={true}
                                        name="lastName"
                                        id="input_last_name"
                                        value={props.info.lastName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onFieldChange(e)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row gridGap--small">
                            <div className="col-xs-8 col-sm-9">
                                <div
                                    className={
                                        props.showErrorFields ? 'form-group error' : 'form-group'
                                    }>
                                    <label htmlFor="input_street">Straße *</label>
                                    <input
                                        type="text"
                                        className="text"
                                        required={true}
                                        name="street"
                                        id="input_street"
                                        value={props.info.street}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onFieldChange(e)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-xs-4 col-sm-3">
                                <div
                                    className={
                                        props.showErrorFields ? 'form-group error' : 'form-group'
                                    }>
                                    <label htmlFor="input_street_no">Nr *</label>
                                    <input
                                        type="text"
                                        className="text"
                                        required={true}
                                        name="streetNo"
                                        id="input_street_no"
                                        value={props.info.streetNo}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onFieldChange(e)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row gridGap--small">
                            <div className="col-xs-4 col-sm-3">
                                <div
                                    className={
                                        props.showErrorFields ? 'form-group error' : 'form-group'
                                    }>
                                    <label htmlFor="input_postal">Postleitzahl *</label>
                                    <input
                                        type="text"
                                        className="text"
                                        required={true}
                                        name="postal"
                                        id="input_postal"
                                        value={props.info.postal}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onFieldChange(e)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-xs-8 col-sm-9">
                                <div
                                    className={
                                        props.showErrorFields ? 'form-group error' : 'form-group'
                                    }>
                                    <label htmlFor="input_city">Stadt *</label>
                                    <input
                                        type="text"
                                        className="text"
                                        required={true}
                                        name="city"
                                        id="input_city"
                                        value={props.info.city}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onFieldChange(e)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row gridGap--small">
                            <div className="col-xs-12 col-sm-6">
                                <div
                                    className={
                                        props.showErrorFields ? 'form-group error' : 'form-group'
                                    }>
                                    <label htmlFor="input_mail">E-Mail-Adresse *</label>
                                    <input
                                        type="text"
                                        className="text"
                                        required={true}
                                        name="email"
                                        id="input_mail"
                                        value={props.info.email}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onFieldChange(e)
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                className={
                                    props.showErrorFields ? 'form-group error' : 'form-group'
                                }>
                                <div className="form-group">
                                    <label htmlFor="input_phone">Telefonnummer *</label>
                                    <input
                                        type="text"
                                        className="text"
                                        name="phone"
                                        id="input_phone"
                                        value={props.info.phone}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onFieldChange(e)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {!refCode && (
                            <div>
                                <div className="row gridGap--small">
                                    <div className="col-xs-12">
                                        <div className="marginBottom--small clearfloat">
                                            Wie sind Sie auf uns aufmerksam geworden?
                                        </div>
                                    </div>
                                </div>

                                <div className="row gridGap--small">
                                    <div className="col-xs-12">
                                        <div className="form-group select">
                                            <div className="input select_body ">
                                                <select
                                                    className="select"
                                                    name="source"
                                                    id="input_select_consultant"
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLSelectElement>
                                                    ) => onFieldChange(e)}>
                                                    <option>Bitte wählen</option>
                                                    <option value="Suchmaschine">
                                                        Suche im Internet
                                                    </option>
                                                    <option value="Werbung im Internet">
                                                        Werbung im Internet
                                                    </option>
                                                    <option value="Empfehlung">Empfehlung</option>
                                                    <option value="Verkaufsberater">
                                                        Verkaufsberater vor Ort
                                                    </option>
                                                    <option value="Von Freund geworben">
                                                        Von Freund geworben
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(props.info.questionnaire?.source === 'Verkaufsberater' ||
                            props.info.questionnaire?.source === 'Von Freund geworben') && (
                            <div className="row gridGap--small">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="input_name_consultant">
                                            {props.info.questionnaire?.source === 'Verkaufsberater'
                                                ? 'Name des Verkaufsberaters'
                                                : 'Name des Freundes'}
                                        </label>
                                        <input
                                            type="text"
                                            className="text"
                                            name="referrerName"
                                            id="input_name_consultant"
                                            value={props.info.questionnaire?.referrerName}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                onFieldChange(e)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="row gridGap--small">
                            <div className="col-xs-12">
                                <div className="configuratorContentOptions">
                                    <div className="form-group checkbox-group">
                                        <label className="specialcheckbox">
                                            <input
                                                className="form-check-input"
                                                id={'tos-input'}
                                                type="checkbox"
                                                value="privacy"
                                                checked={props.tosAccepted}
                                                onChange={() =>
                                                    props.setTOSAccepted(!props.tosAccepted)
                                                }
                                            />
                                            <label htmlFor={'tos-input'} />
                                            <p>
                                                <span>
                                                    Ich stimme den allgemeinen Geschäftsbedingungen
                                                    zu und erkläre mich mit der Verwendung meiner
                                                    personenbezogenen Daten sowie deren Verarbeitung
                                                    einverstanden soweit diese für das
                                                    personalisierte Angebot erforderlich sind.
                                                </span>
                                            </p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PersonalView;
