import React from 'react';

import { Card, PackageItem, View } from '../../types';
import CardsSelect from '../../components/CardsSelect/CardsSelect';
import { RepositoryContext } from '../../App';
import { ViewId } from '../../enums';

type Props = {
    view: View;
    items: PackageItem[];
    setView: (view: View) => void;
    setItems: (items: PackageItem[]) => void;
};

const InverterView = (props: Props) => {
    const { repository } = React.useContext(RepositoryContext);
    const [cards, setCards] = React.useState<Card[]>([]);

    const getInverterCards = React.useCallback(async () => {
        const cards = repository.getCardsFromView(ViewId.INVERTER);
        const mapped = await Promise.all(
            cards.map(async (c) => {
                const mod = props.items.find((i: PackageItem) => i.viewId === ViewId.MODULE);
                if (!mod) return;

                const units = mod.units;
                const price = await repository.getInverterPrice(c.id, units);
                if (!price) return;

                return {
                    ...c,
                    price
                };
            })
        );
        const filtered = mapped.filter((card: Card | undefined) => !!card) as Card[];
        setCards([...filtered]);
    }, [props.items, repository]);

    React.useEffect(() => {
        getInverterCards();
    }, [getInverterCards]);

    return (
        <div data-testid="inverter-view" className="inverter-view-container">
            <CardsSelect
                items={props.items}
                setItems={props.setItems}
                cards={cards}
                view={props.view}
                setView={props.setView}
            />
        </div>
    );
};

export default InverterView;
