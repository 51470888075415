import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Card, CardOption, PackageItem } from '../../types';
import { RepositoryContext } from '../../App';
import CardDropdown from '../CardDropdown/CardDropdown';
import { formatPrice } from '../../utils';
import DisabledOverlay from '../DisabledOverlay/DisabledOverlay';

const displayCardPrice = process.env.DISPLAY_CARD_PRICE ?? false;

type Props = {
    card: Card;
    items: PackageItem[];
    setItems: (items: PackageItem[]) => void;
};

const CardContent = (props: Props) => {
    const { repository } = React.useContext(RepositoryContext);
    const [isSelected, setIsSelected] = React.useState<boolean>(false);

    const disabledIds = React.useMemo(() => {
        const cards = props.items.map((item) => repository.getCardById(item.cardId));
        return repository.getCardsDisabledIds(cards as Card[]);
    }, [props.items, repository]);

    const [isDisabled, setIsDisabled] = React.useState<boolean>(
        disabledIds.includes(props.card.id)
    );

    const [optionSelected, setOptionSelected] = React.useState<CardOption | undefined>(undefined);
    const [defaultOptionId, setDefaultOptionId] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        const viewCard = props.items.find((item) => item.cardId === props.card.id);
        if (viewCard) {
            setIsSelected(true);
            return;
        }
        setIsSelected(false);
    }, [props.card.id, props.items]);

    const validateUnitAmount = (item: PackageItem) => {
        const units = item.units;
        let newInput = units;
        if (units && units > 33) {
            newInput = 33;
        }

        if (units && units < 9) {
            newInput = 9;
        }

        if (newInput != units) {
            item.units = newInput;
        }
        return item;
    };

    const handleOnClick = () => {
        if (isDisabled) return;

        const { id: cardId, viewId, price, units } = props.card;
        const view = repository.getViewById(viewId);
        const nextViewIds = repository.getNextViewIds(view?.previousId);

        const defaultOption = props.card.options?.find((opt) => opt.id === defaultOptionId);

        const option = optionSelected ?? defaultOption;

        const item: PackageItem = {
            cardId,
            viewId,
            units,
            price: option ? option.price : price,
            options: option ? [option] : undefined
        };

        const validatedItem = validateUnitAmount(item);

        const filteredItems = props.items.filter((item) => !nextViewIds.includes(item.viewId));
        props.setItems([...filteredItems, validatedItem]);
    };

    return (
        <div
            className={`configuratorContentCard__item${isDisabled ? ' disabled' : ''}`}
            onClick={handleOnClick}>
            {isDisabled && <DisabledOverlay />}
            <div className="form-group radiobox-group">
                <label className="specialradiobox">
                    <input
                        className="form-check-input"
                        id={props.card.id}
                        type="radio"
                        value="privacy"
                        disabled={isDisabled}
                        checked={isSelected}
                    />
                    <label htmlFor={props.card.id}>{isSelected && <CheckIcon />}</label>
                </label>
            </div>
            <div className="configuratorContentCard__image">
                <img src={props.card.data.image} />
            </div>
            <div className="configuratorContentCard__subline">
                {props.card.data.highlightText || '\u00A0'}
            </div>
            <div className="configuratorContentCard__title">{props.card.data.title}</div>
            <div className="configuratorContentCard__description">
                {props.card.data.typeDescription}
            </div>
            <div className="configuratorContentCard__attributes">
                {/* <ul> */}
                {props.card.data.featuresInfo.map((info, i) => (
                    <p key={`${props.card.id}-feat-${i}`}>{info}</p>
                ))}
                {/* {props.card.data.techInfo.map((info, i) => (
                        <li key={`${props.card.id}-tech-${i}`}>{info}</li>
                    ))} */}
                {/* </ul> */}
            </div>
            {displayCardPrice && (
                <div className="configuratorContentCard__price">
                    {props.card.price ? formatPrice(props.card.price) : ''}
                </div>
            )}
            <CardDropdown
                card={props.card}
                items={props.items}
                onSelectOption={setOptionSelected}
                setItems={props.setItems}
                cardDisabled={isDisabled}
                onDisableCard={setIsDisabled}
                defaultOptionId={defaultOptionId}
                setDefaultOptionId={setDefaultOptionId}
            />
        </div>
    );
};

export default CardContent;
