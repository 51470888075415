import React from 'react';

import { PackageItem, View } from '../../types';
import CardsSelect from '../../components/CardsSelect/CardsSelect';
import { RepositoryContext } from '../../App';
import { ViewId } from '../../enums';

type Props = {
    view: View;
    items: PackageItem[];
    setView: (view: View) => void;
    setItems: (items: PackageItem[]) => void;
};

const StorageView = (props: Props) => {
    const { repository } = React.useContext(RepositoryContext);

    const storageCards = React.useMemo(() => {
        const cards = repository.getCardsFromView(ViewId.STORAGE);
        return cards.map((c) => {
            const sto = repository.storages.find((st) => st.id === c.id);

            return {
                ...c,
                options: sto?.options
            };
        });
    }, [repository]);

    return (
        <div data-testid="inverter-view" className="inverter-view-container">
            <CardsSelect
                items={props.items}
                setItems={props.setItems}
                cards={storageCards}
                view={props.view}
                setView={props.setView}
            />
        </div>
    );
};

export default StorageView;
