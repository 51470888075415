import React from 'react';

import { Card, PackageItem, View } from '../../types';
import CardContent from '../CardContent/CardContent';
import { RepositoryContext } from '../../App';

type Props = {
    cards: Card[];
    items: PackageItem[];
    view: View;
    setItems: (items: PackageItem[]) => void;
    setView: (view: View) => void;
};

const CardsSelect = (props: Props) => {
    const { repository } = React.useContext(RepositoryContext);
    const [showSkipButton, setShowSkipButton] = React.useState<boolean>(false);

    React.useEffect(() => {
        const noCard = props.cards.find((card) => card.id.startsWith('no-'));
        if (noCard) {
            setShowSkipButton(true);
        }
    }, [props.cards]);

    const cardsContent = React.useMemo(() => {
        return props.cards.map((card) => {
            if (card.id.startsWith('no-')) return false;

            return (
                <CardContent
                    key={card.id}
                    card={card}
                    items={props.items}
                    setItems={props.setItems}
                />
            );
        });
    }, [props.cards, props.items, props.setItems]);

    const skipButtonOnClick = () => {
        const nextView = repository.getViewById(props.view.nextId);
        if (!nextView) return;

        const nextViewIds = repository.getNextViewIds(props.view?.previousId);
        const filteredItems = props.items.filter((item) => !nextViewIds.includes(item.viewId));

        props.setItems([...filteredItems]);
        props.setView(nextView);
    };

    return (
        <div>
            {showSkipButton && (
                <div className="button_row">
                    <a className="button_primary next" onClick={skipButtonOnClick}>
                        Weiter ohne Auswahl
                    </a>
                </div>
            )}
            <div data-testid="cards-select-container" className="configuratorContentCards">
                {cardsContent}
            </div>
        </div>
    );
};

export default CardsSelect;
